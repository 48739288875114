//@js-check
import Vue from "vue";
import Router from "vue-router";

import Layout from "@/layouts/Layout.vue";

// Routes
import dashboardRoute from "./modules/homepage";
import signalsOperRoute from "./modules/signals_oper";
import signalFromOperRoute from "./modules/signal_from_oper";
import breakdownsRoute from "./modules/breakdowns";
import waterMeterRoute from "./modules/water_meters";
import gisNomenclaturesRoute from "./modules/gis_nomenclatures";
import importFileRoute from "./modules/import_file";
// import clientWaterMeterListRoute from "./modules/client_water_meter_list";
// import clientSearchRoute from "./modules/client_search";
// import addressSearchRoute from "./modules/address_search";
//import addressMappingRoute from "./modules/address_mapping";
import logsRoute from "./modules/logs";
import versionsRoute from "./modules/versions";

import administrationRoute from "./modules/administration";
import NotFound_404 from "@/views/error/404.vue";

// Dashboard
import idEditor from "./dashboard/IDEditor";
import reports from "./dashboard/reports";
import thematicMap from "./dashboard/thematicMap";
import waterMeters from "./dashboard/waterMeters";
import breakdowns from "./dashboard/breakdowns";
//import measurements from "./dashboard/measurements";
//import waterMetersZones from "./dashboard/waterMetersZones";
import signals from "./dashboard/signals";
import valves from "./dashboard/valves";
import qdata from "./dashboard/qdata";

class VueRouterEx extends Router {
  constructor(options) {
    super(options);
    const { addRoutes } = this.matcher;
    const { routes } = options;

    this.routes = routes;

    this.matcher.addRoutes = (newRoutes) => {
      this.routes.push(...newRoutes);
      addRoutes(newRoutes);
    };
  }
}

export const constantRoutes = [
  {
    path: "/error",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "404",
        component: NotFound_404,
        meta: {
          title: "Грешка",
        },
        hidden: true,
      },
    ],
  },
];

export const asyncRoutes = [
  dashboardRoute,
  signalsOperRoute,
  signalFromOperRoute,
  breakdownsRoute,
  waterMeterRoute,
  gisNomenclaturesRoute,
  importFileRoute,
  // clientWaterMeterListRoute,
  // clientSearchRoute,
  // addressSearchRoute,
  //addressMappingRoute,
  logsRoute,
  versionsRoute,
  administrationRoute,

  {
    path: "*",
    component: Layout,
    hidden: true,
    meta: {
      title: "404 Страницата не е намерена",
      actions: [],
    },
    children: [
      {
        name: "404 Error",
        path: "",
        component: NotFound_404,
        meta: {
          title: "404 Страницата не е намерена",
          actions: [],
        },
        hidden: true,
      },
    ],
  },
];

export const dashboardRoutes = [
  idEditor,
  reports,
  thematicMap,
  waterMeters,
  breakdowns,
  //measurements,
  // waterMetersZones,
  signals,
  valves,
  qdata,
];

Vue.use(VueRouterEx);

var _constantRoutes = [...constantRoutes];

const createRouter = () =>
  new VueRouterEx({
    //mode: 'history',
    scrollBehavior: (to) => {
      if (to.hash) {
        return { selector: to.hash };
      } else {
        return { x: 0, y: 0 };
      }
    },
    linkExactActiveClass: "nav-item active",
    routes: _constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;

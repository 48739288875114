<template>
  <div>
    <iframe
      :src="iframeSrc"
      frameBorder="0"
      width="100%"
      height="600px"
      title="Versions"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: "VersionsIframe",

  computed: {
    iframeSrc() {
      return process.env.VUE_APP_VERSIONS_URL;
    },
  },
};
</script>
